import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SideMenuEventService {

    
    internalStatus = false;

    private status = new BehaviorSubject(this.internalStatus);
    currentStatus = this.status.asObservable();

    constructor() { }

    openSide() {
        if (!this.internalStatus) {
            this.internalStatus = true;
            this.status.next(true);
        }
    }

    closeSide() {
        if (this.internalStatus) {
            this.internalStatus = false;
            this.status.next(false);
        }
    }

    toggleSide() {
        this.internalStatus = !this.internalStatus;
        this.status.next(this.internalStatus);
    }

}
