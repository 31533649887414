<app-header [showMenu]="false"></app-header>

<div class="content mat-typography bg">
    <div class="content-container">

      <app-card-basic cardTitle="Forgot Password">
        <div card-body>
            <div>
                <mat-form-field class="full-width">
                    <input matInput [(ngModel)]="emailAddress" placeholder="Email Address">
                </mat-form-field>
                <br />

                <button *ngIf="!sendingReset" mat-raised-button style="float: right;" (click)="resetPassword()" color="primary">Reset Password</button>

                <button *ngIf="sendingReset" mat-icon-button disabled color="primary" style="float: right;">
                    <mat-icon>
                        <mat-spinner color="primary" diameter="20">
                        </mat-spinner>
                    </mat-icon>
                </button>

            </div>
        </div>
      </app-card-basic>

  </div>

</div>
