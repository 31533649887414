/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./accessdenied.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../header/header.component.ngfactory";
import * as i3 from "../header/header.component";
import * as i4 from "../side-menu/side-menu-event.service";
import * as i5 from "../auth/auth.service";
import * as i6 from "@angular/flex-layout/core";
import * as i7 from "../card-basic/card-basic.component.ngfactory";
import * as i8 from "../card-basic/card-basic.component";
import * as i9 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i10 from "@angular/router";
import * as i11 from "@angular/material/button";
import * as i12 from "@angular/cdk/platform";
import * as i13 from "@angular/cdk/a11y";
import * as i14 from "@angular/platform-browser/animations";
import * as i15 from "./accessdenied.component";
var styles_AccessdeniedComponent = [i0.styles];
var RenderType_AccessdeniedComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AccessdeniedComponent, data: {} });
export { RenderType_AccessdeniedComponent as RenderType_AccessdeniedComponent };
export function View_AccessdeniedComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(1, 245760, null, 0, i3.HeaderComponent, [i4.SideMenuEventService, i5.AuthService, i6.MediaObserver], { showMenu: [0, "showMenu"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 12, "div", [["class", "content mat-typography bg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 11, "div", [["class", "content-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 10, "app-card-basic", [["cardTitle", "Access Denied"]], null, null, null, i7.View_CardBasicComponent_0, i7.RenderType_CardBasicComponent)), i1.ɵdid(5, 114688, null, 0, i8.CardBasicComponent, [], { cardTitle: [0, "cardTitle"] }, null), (_l()(), i1.ɵeld(6, 0, null, 0, 8, "div", [["card-body", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["You do not have permission to access this page. Please use the button below to go back to the home page."])), (_l()(), i1.ɵeld(10, 0, null, null, 4, "button", [["color", "primary"], ["mat-raised-button", ""], ["style", "float: right;"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_MatButton_0, i9.RenderType_MatButton)), i1.ɵdid(11, 16384, null, 0, i10.RouterLink, [i10.Router, i10.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(12, 1), i1.ɵdid(13, 180224, null, 0, i11.MatButton, [i1.ElementRef, i12.Platform, i13.FocusMonitor, [2, i14.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, [" Go to Home Page "]))], function (_ck, _v) { var currVal_0 = false; _ck(_v, 1, 0, currVal_0); var currVal_1 = "Access Denied"; _ck(_v, 5, 0, currVal_1); var currVal_4 = _ck(_v, 12, 0, "/"); _ck(_v, 11, 0, currVal_4); var currVal_5 = "primary"; _ck(_v, 13, 0, currVal_5); }, function (_ck, _v) { var currVal_2 = (i1.ɵnov(_v, 13).disabled || null); var currVal_3 = (i1.ɵnov(_v, 13)._animationMode === "NoopAnimations"); _ck(_v, 10, 0, currVal_2, currVal_3); }); }
export function View_AccessdeniedComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-accessdenied", [], null, null, null, View_AccessdeniedComponent_0, RenderType_AccessdeniedComponent)), i1.ɵdid(1, 114688, null, 0, i15.AccessdeniedComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AccessdeniedComponentNgFactory = i1.ɵccf("app-accessdenied", i15.AccessdeniedComponent, View_AccessdeniedComponent_Host_0, {}, {}, []);
export { AccessdeniedComponentNgFactory as AccessdeniedComponentNgFactory };
