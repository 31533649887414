<form class="form" [formGroup]="form"  [ngClass]="{'inline': inline}">
  <mat-form-field class="form-field" appearance="outline">
    <input type="text"
           placeholder="Search"
           aria-label="Search"
           matInput
           formControlName="search"
           [matAutocomplete]="auto">


              <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" >

                  <mat-option *ngIf="isLoading" class="is-loading"><mat-spinner diameter="20"></mat-spinner></mat-option>
                  <ng-container *ngIf="!isLoading && searchTypeID == 1">
                    <mat-option *ngFor="let case of filteredItems" [value]="case" [routerLink]="['/case', case.id]">
                    <span>{{ case.type.caseTypeName }} - {{ case.caseReference }} - {{ case.partyOneFirstName }} {{ case.partyOneLastName }} ({{ case.paralegalUserFirstName }} {{ case.paralegalUserLastName }}, {{ case.financialAdviserFirstName }} {{ case.financialAdviserLastName }}) - {{case.partyOnePostcode}}</span>
                    <span *ngIf="!inline"> - {{ case.caseStatusTypeText }}</span>
                    </mat-option>
                  </ng-container>

                  <ng-container *ngIf="!isLoading && searchTypeID == 2 ">
                    <mat-option *ngFor="let user of filteredItems" [value]="user" [routerLink]="['/user', user.id, 'admin']">
                    <span>{{ user.firstName }} {{ user.lastName }} - {{user.emailAddress}} - {{user.roleName}} </span>
                    </mat-option>
                  </ng-container>

                  <ng-container *ngIf="!isLoading && searchTypeID == 3">
                      <mat-option *ngFor="let user of filteredItems" [value]="user" [routerLink]="['/user', user.id, 'admin']">
                      <span>{{ user.firstName }} {{ user.lastName }} - {{ user.companyGroup.name }} - {{user.emailAddress}} - {{user.roleName}} </span>
                      </mat-option>
                    </ng-container>

                  <ng-container *ngIf="!isLoading && searchTypeID == 4">
                    <mat-option *ngFor="let company of filteredItems" [value]="company" [routerLink]="['/companygroup', company.id, 'admin']">
                    <span>{{ company.name }}</span>
                    </mat-option>
                  </ng-container>

                  <ng-container *ngIf="!isLoading && searchTypeID == 5">
                      <mat-option *ngFor="let consultant of filteredItems" [value]="consultant" [routerLink]="['/consultants', consultant.id, 'admin']">
                      <span>{{ consultant.name }} - {{ consultant.emailAddress }} - {{ consultant.postcode }} - {{ consultant.borough }}</span>
                      </mat-option>
                    </ng-container>

                </mat-autocomplete>

  </mat-form-field>
</form>
