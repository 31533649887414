<app-header [showMenu]="false"></app-header>

<div class="content mat-typography bg">
    <div class="content-container">

      <app-card-basic cardTitle="Session Expired">
        <div card-body>
            <div>
                <p>Your session has expired. Please use the button below to go to the login page.</p>

                <button mat-raised-button [routerLink]="['/login']" color="primary" style="float: right;">
                    Go to Login Page
                </button>

            </div>
        </div>
      </app-card-basic>

  </div>

</div>
