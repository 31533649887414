import { Component, OnInit,Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import { FormGroup, FormControl } from '@angular/forms';

export interface DialogData {
  keyDate: null;
  notes: '';
}

@Component({
  selector: 'app-basic-popup-yes-no',
  templateUrl: './basic-popup-yes-no.component.html',
  styleUrls: ['./basic-popup-yes-no.component.scss']
})
export class BasicPopupYesNoComponent implements OnInit {


  public messageHeader: string;
  public popupText1: string;
  public popupText2: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData,
              public dialogRef: MatDialogRef<BasicPopupYesNoComponent>) {

    this.messageHeader = this.data['messageHeader'];
    this.popupText1 = this.data['popupText1'];
    this.popupText2 = this.data['popupText2'];

  }

  ngOnInit() {
  }

  yes(){
    this.dialogRef.close(true);
  }

  no(){
    this.dialogRef.close(false);
  }


}
