import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/portal/services/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.scss']
})
export class ResetpasswordComponent implements OnInit {

  emailAddress: string;
  passwordResetToken: string;
  newpassword: string;
  retypePassword: string;

  creatingPassword: boolean = false;

  constructor(
    private userService: UserService,
    public router: Router,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute) {

      this.route.queryParams.subscribe(params => {

        if(params['token'])
          this.passwordResetToken = params['token'];
      });

    }

  ngOnInit() {
  }

  createNewPassword() {

    this.creatingPassword = true;

    this.userService.createNewPassword({ emailAddress: this.emailAddress, passwordResetToken: this.passwordResetToken, password: this.newpassword, retypePassword: this.retypePassword  }).subscribe(response => {

          this.openSnackBar("Password Updated", 'Close');
          this.router.navigateByUrl('/login');
          this.creatingPassword = false;

      }, error => {

        this.creatingPassword = false;


        var errorMessage = "Password Reset Failed";
        if(error.status == 999)
          errorMessage = error.error;
        else
        {

        if (error.error !== null && error.error.message !== undefined) {
          errorMessage = error.error.message;
        }
        }

        this.openSnackBar(errorMessage, 'Close');

      });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
        duration: 5000,
    });
  }

}
