<div mat-dialog-content class="mat-typography">

    <h1>{{messageHeader}}</h1>

    <h2>{{popupText1}}</h2>
      <div>
          {{popupText2}}
      </div>

      <div class="buttons">
        <button mat-stroked-button [disabled]="false" color="primary" type="button" (click)="ok()">OK</button>
      </div>

</div>
