import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import { FilesService } from '../../portal/services/files.service';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

export interface DialogData {
  fileName: '';
}

@Component({
  selector: 'app-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.scss']
})
export class FilePreviewComponent implements OnInit {

  fileId: number;
  fileURL: any;
  imageLoading: boolean = true;

  public faTimes = faTimes;

  constructor(public dialogRef: MatDialogRef<FilePreviewComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData, private filesService: FilesService) {

    this.fileId = this.data['fileId'];

      this.filesService.GetFile(this.fileId).subscribe((file) => {

        this.imageLoading = true;
        this.createImageFromBlob(file.body);

    });
  }

  ngOnInit() {
  }

  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
       this.fileURL = reader.result;

    }, false);

    if (image) {
       reader.readAsDataURL(image);
       this.imageLoading = false;
    }
   }

   close(){
    this.dialogRef.close(false);
   }

}
