
<!--div class="buttonBar">
  <button mat-icon-button (click)="close()" color="primary" class="closeButton">
      <fa-icon [icon]="faTimes" ></fa-icon>
  </button>
</div-->


<div class="buttonBar">
    <span (click)="close()" class="closeButton">
      <fa-icon color="primary" [icon]="faTimes" ></fa-icon>
    </span>
</div>



<img *ngIf="!imageLoading" [src]="fileURL">
<mat-spinner *ngIf="imageLoading" color="primary" diameter="20">
</mat-spinner>
