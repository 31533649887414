import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/portal/services/user.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.scss']
})
export class ForgotpasswordComponent implements OnInit {

  emailAddress: string;

  sendingReset: boolean = false;

  constructor(private userService: UserService, public router: Router, private snackBar: MatSnackBar) { }

  ngOnInit() {
  }

  resetPassword() {

    this.sendingReset = true;
    this.userService.resetPassword({ emailAddress: this.emailAddress }).subscribe(response => {
 
          this.openSnackBar("Password Reset Email Sent", 'Close');
          this.router.navigateByUrl('/login');

          this.sendingReset = false;
  
      }, error => {
        var errorMessage = "Password Reset Failed";
        if(error.error !== null && error.error.message !== undefined)
        {
          errorMessage = error.error.message;
        }

        this.sendingReset = false;
        this.openSnackBar(errorMessage, 'Close');

      });
  }


  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
        duration: 5000,
    });
  }
}
