/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./card-basic.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./card-basic.component";
var styles_CardBasicComponent = [i0.styles];
var RenderType_CardBasicComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CardBasicComponent, data: {} });
export { RenderType_CardBasicComponent as RenderType_CardBasicComponent };
function View_CardBasicComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "title-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.cardTitle; _ck(_v, 2, 0, currVal_0); }); }
export function View_CardBasicComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "container mat-typography mat-elevation-z1"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CardBasicComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "main"]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "footer"]], null, null, null, null, null)), i1.ɵncd(null, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.cardTitle; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_CardBasicComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-card-basic", [], null, null, null, View_CardBasicComponent_0, RenderType_CardBasicComponent)), i1.ɵdid(1, 114688, null, 0, i3.CardBasicComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CardBasicComponentNgFactory = i1.ɵccf("app-card-basic", i3.CardBasicComponent, View_CardBasicComponent_Host_0, { cardTitle: "cardTitle" }, {}, ["[card-body]", "[card-footer]"]);
export { CardBasicComponentNgFactory as CardBasicComponentNgFactory };
