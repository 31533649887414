import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
import * as i2 from "@angular/router";
var AuthGuardService = /** @class */ (function () {
    function AuthGuardService(auth, router) {
        this.auth = auth;
        this.router = router;
    }
    AuthGuardService.prototype.canActivate = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.auth.isAuthenticated()];
                    case 1:
                        result = _a.sent();
                        if (!result) {
                            //redirect to logout/login
                            this.router.navigateByUrl('/login');
                            return [2 /*return*/, false];
                        }
                        return [2 /*return*/, true];
                }
            });
        });
    };
    AuthGuardService.ngInjectableDef = i0.defineInjectable({ factory: function AuthGuardService_Factory() { return new AuthGuardService(i0.inject(i1.AuthService), i0.inject(i2.Router)); }, token: AuthGuardService, providedIn: "root" });
    return AuthGuardService;
}());
export { AuthGuardService };
