/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./file-preview.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-spinner";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/common";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "../../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i8 from "@fortawesome/angular-fontawesome";
import * as i9 from "@angular/platform-browser";
import * as i10 from "./file-preview.component";
import * as i11 from "@angular/material/dialog";
import * as i12 from "../../portal/services/files.service";
var styles_FilePreviewComponent = [i0.styles];
var RenderType_FilePreviewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FilePreviewComponent, data: {} });
export { RenderType_FilePreviewComponent as RenderType_FilePreviewComponent };
function View_FilePreviewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fileURL; _ck(_v, 0, 0, currVal_0); }); }
function View_FilePreviewComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["color", "primary"], ["diameter", "20"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i2.View_MatSpinner_0, i2.RenderType_MatSpinner)), i1.ɵdid(1, 49152, null, 0, i3.MatSpinner, [i1.ElementRef, i4.Platform, [2, i5.DOCUMENT], [2, i6.ANIMATION_MODULE_TYPE], i3.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { color: [0, "color"], diameter: [1, "diameter"] }, null)], function (_ck, _v) { var currVal_3 = "primary"; var currVal_4 = "20"; _ck(_v, 1, 0, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 1).diameter; var currVal_2 = i1.ɵnov(_v, 1).diameter; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_FilePreviewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "buttonBar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "span", [["class", "closeButton"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "fa-icon", [["class", "ng-fa-icon"], ["color", "primary"]], [[8, "innerHTML", 1]], null, null, i7.View_FaIconComponent_0, i7.RenderType_FaIconComponent)), i1.ɵdid(3, 573440, null, 0, i8.FaIconComponent, [i9.DomSanitizer, i8.FaIconService], { iconProp: [0, "iconProp"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FilePreviewComponent_1)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FilePreviewComponent_2)), i1.ɵdid(7, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.faTimes; _ck(_v, 3, 0, currVal_1); var currVal_2 = !_co.imageLoading; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.imageLoading; _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).renderedIconHTML; _ck(_v, 2, 0, currVal_0); }); }
export function View_FilePreviewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-file-preview", [], null, null, null, View_FilePreviewComponent_0, RenderType_FilePreviewComponent)), i1.ɵdid(1, 114688, null, 0, i10.FilePreviewComponent, [i11.MatDialogRef, i11.MAT_DIALOG_DATA, i12.FilesService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FilePreviewComponentNgFactory = i1.ɵccf("app-file-preview", i10.FilePreviewComponent, View_FilePreviewComponent_Host_0, {}, {}, []);
export { FilePreviewComponentNgFactory as FilePreviewComponentNgFactory };
