import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  emailAddress: string;
  password: string;
  subscription: Subscription;
  loggingIn: boolean = false;

  constructor( public authService: AuthService,public route: ActivatedRoute, public router: Router, private snackBar: MatSnackBar) {

    this.subscription = authService.isLogin.subscribe(loggedIn => {

      if (!loggedIn) {
        this.loggingIn = false;
      } else if (loggedIn) {

        this.loggingIn = true;
        if(this.router.url == '/login') {
        //if (this.route.snapshot.url[0].path == 'login') {
          this.router.navigateByUrl('/');
        }
      }
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe;
    }
  }

  login() {

    this.loggingIn = true;
    //this.authService.login({ emailAddress: this.emailAddress, password: this.password })
    this.authService.login2({ emailAddress: this.emailAddress, password: this.password }).subscribe( result => {

      this.loggingIn = true;
      this.router.navigateByUrl('/');
    }, error => {

      this.loggingIn = false;
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
        duration: 5000,
    });
  }

}
