import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable({
  providedIn: 'root'
})
export class SearchService {

  private casesUrl = environment.apiUrl + 'cases/';
  private usersUrl = environment.apiUrl + 'users/';
  private companyGroupsUrl = environment.apiUrl + 'companygroups/';
  private externalConsultantsUrl = environment.apiUrl + 'externalconsultants/';

  //public caseAllocated = new EventEmitter();
  //public caseAllocatedObservable = this.caseAllocated.asObservable();

  constructor(private http: HttpClient) {

  }

  searchCases(request: string): Observable<any[]> {
    return this.http.post<any>(this.casesUrl + 'search', {searchTerm: request}, httpOptions);
  }

  searchByCaseStatusType(caseStatusType: number, request: string): Observable<any[]> {
    return this.http.post<any>(this.casesUrl + 'SearchByCaseStatusType/' + caseStatusType, {searchTerm: request}, httpOptions);
  }

  searchInternalUsers(request: string): Observable<any[]> {
    return this.http.post<any>(this.usersUrl + 'searchinternal', {searchTerm: request}, httpOptions);
  }

  searchExternalUsers(request: string): Observable<any[]> {
    return this.http.post<any>(this.usersUrl + 'searchexternal', {searchTerm: request}, httpOptions);
  }

  searchCompanyGroups(request: string): Observable<any[]> {
    return this.http.post<any>(this.companyGroupsUrl + 'search', {searchTerm: request}, httpOptions);
  }

  searchConsultants(request: string): Observable<any[]> {
    return this.http.post<any>(this.externalConsultantsUrl + 'search', {searchTerm: request}, httpOptions);
  }

}
