import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './account/login/login.component';
import { ForgotpasswordComponent } from './account/forgotpassword/forgotpassword.component';
import { ResetpasswordComponent } from './account/resetpassword/resetpassword.component';

import { AuthGuardService } from './shared/auth/auth.guard';
import { ExpiredComponent } from './account/expired/expired.component';
import { AccessdeniedComponent } from './shared/accessdenied/accessdenied.component';
import { PagenotfoundComponent } from './shared/pagenotfound/pagenotfound.component';
import { PortalModule } from './portal/portal.module';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'forgotpassword', component: ForgotpasswordComponent },
  { path: 'resetpassword', component: ResetpasswordComponent },
  { path: 'expired', component: ExpiredComponent },
  //{ path: '', loadChildren: () => PortalModule, canActivate: [AuthGuardService] },
  { path: '', loadChildren: './portal/portal.module#PortalModule', canActivate: [AuthGuardService] },
  {
    path: 'denied',
    component: AccessdeniedComponent
  },
  {
    path: '**',
    component: PagenotfoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
  })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
