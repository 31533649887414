import { NgModule, ModuleWithProviders } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { HeaderComponent } from './header/header.component';
import { CardComponent } from './card/card.component';
import { CardLabelComponent } from './card/card-label/card-label.component';
import { CardBasicComponent } from './card-basic/card-basic.component';
import { BasicInputComponent } from '../shared/components/basic-input/basic-input.component';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatFormFieldModule, MatInputModule, MatButtonModule,  MatProgressSpinnerModule, MatSnackBarModule, MatButtonToggleModule,  MatIconModule, MatDialogModule, MatMenuModule, MatDatepickerModule, MatCheckboxModule, MatAutocompleteModule, MatListModule } from '@angular/material';
import { MatSidenavModule, MatSelectModule } from '@angular/material';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { CardInstructionComponent } from './card/card-instruction/card-instruction.component';

import { UserService } from 'src/app/portal/services/user.service';
import { FileUploaderComponent } from './file-uploader/file-uploader.component';
import { ReactiveFormsModule } from '@angular/forms';
import { DateFilterComponent } from './components/table-filters/date-filter/date-filter.component';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { StringFilterComponent } from './components/table-filters/string-filter/string-filter.component';
import { BooleanFilterComponent } from './components/table-filters/boolean-filter/boolean-filter.component';
import { DropdownFilterComponent } from './components/table-filters/dropdown-filter/dropdown-filter.component';
import { AutocompleteFilterComponent } from './components/table-filters/autocomplete-filter/autocomplete-filter.component';
import { FilePreviewComponent } from './file-preview/file-preview.component';
import { ZeroPaddingPipe } from './pipes/zero-padding.pipe';
import { SubSectionComponent } from './card/sub-section/sub-section.component';
import { AddressLookupComponent } from './address-lookup/address-lookup.component';
import { AddressLookupButtonComponent } from './address-lookup/address-lookup-button/address-lookup-button.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SearchBarComponent } from '../portal/search/search-bar/search-bar.component';
import { AuthService } from './auth/auth.service';
import { BasicPopupOKComponent } from '../shared/components/basic-popup-ok/basic-popup-ok.component';
import { BasicPopupYesNoComponent } from '../shared/components/basic-popup-yes-no/basic-popup-yes-no.component';
import {ScrollDispatchModule} from '@angular/cdk/scrolling';


@NgModule({
    declarations: [
        HeaderComponent,
        CardBasicComponent,
        BasicInputComponent,
        CardComponent,
        SideMenuComponent,
        CardLabelComponent,
        CardInstructionComponent,
        FileUploaderComponent,
        DateFilterComponent,
        StringFilterComponent,
        BooleanFilterComponent,
        DropdownFilterComponent,
        AutocompleteFilterComponent,
        FilePreviewComponent,
        ZeroPaddingPipe,
        SubSectionComponent,
        AddressLookupComponent,
        AddressLookupButtonComponent,
        SearchBarComponent,
        BasicPopupOKComponent,
        BasicPopupYesNoComponent
    ],
    exports: [
        HeaderComponent,
        CardComponent,
        CardBasicComponent,
        BasicInputComponent,
        SideMenuComponent,
        CardLabelComponent,
        CardInstructionComponent,
        FileUploaderComponent,
        DateFilterComponent,
        StringFilterComponent,
        BooleanFilterComponent,
        DropdownFilterComponent,
        AutocompleteFilterComponent,
        FilePreviewComponent,
        ZeroPaddingPipe,
        SubSectionComponent,
        AddressLookupComponent,
        AddressLookupButtonComponent,
        SearchBarComponent,
        ScrollDispatchModule
    ],
  entryComponents: [FileUploaderComponent, FilePreviewComponent, BasicPopupOKComponent, BasicPopupYesNoComponent],
    imports: [
        RouterModule,
        FormsModule,
        MatInputModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        CommonModule,
        FontAwesomeModule,
        MatSidenavModule,
        MatFormFieldModule,
        MatButtonToggleModule,
        MatIconModule,
        MatDialogModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatFormFieldModule,
        MatMenuModule,
        MatDatepickerModule,
        MatMomentDateModule,
        MatCheckboxModule,
        MatAutocompleteModule,
        MatListModule,
        FlexLayoutModule
    ],
    providers: [UserService]
  })

export class SharedModule {


  static forRoot(): ModuleWithProviders {
    return {
    ngModule: SharedModule,
    providers: [ AuthService ]
    };
}

}
