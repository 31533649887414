import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable()
export class FilesService {

    private url = environment.apiUrl + 'files/';

    constructor( private http: HttpClient, public router: Router) {

    }

    GetFile(fileId: number){

      return this.http.get(this.url + fileId, { observe: 'response', responseType: 'blob'});

    }

    deleteFile(caseId, fileId){
      return this.http.get<any>(this.url + caseId + '/Delete/' + fileId , httpOptions );
    }


  }
