import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SearchRequest } from './interfaces/search-request';
import { SearchResult } from './interfaces/search-result';
import { BehaviorSubject } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable()
export class UserService {

  private url = environment.apiUrl;

  private staffLookupSubject = new BehaviorSubject(null);
  public staffLookup = this.staffLookupSubject.asObservable();

  private userLookupSubject = new BehaviorSubject(null);
  public userLookupLookup = this.userLookupSubject.asObservable();

  constructor(private http: HttpClient, public router: Router) {

    this.getStaffLookup();
  }

  resetPassword(resetPasswordDetails: any) {
    return this.http.post<any>(this.url + 'users/resetpassword', resetPasswordDetails, httpOptions);
  }

  createNewPassword(newPasswordDetails: any) {
    return this.http.post<any>(this.url + 'users/newpassword', newPasswordDetails, httpOptions);
  }

  users(request: SearchRequest) {

    if (request.filterStrings.join() == '') {
      return this.http.get<SearchResult>(`${this.url}users?page=${request.page}&pageSize=${request.pageSize}&orderBy=
        ${request.orderBy}&orderDirection=${request.orderDirection}`);
    }

    var queryString = '';

    for (var i = 0; i < request.filterStrings.length; i++) {
      if (request.filterStrings[i] !== '' && request.filterStrings[i] !== null) {
        queryString += `&filterColumns=${request.filterColumns[i]}&filterStrings=${request.filterStrings[i]}`
      }
    }

    return this.http.get<SearchResult>(`${this.url}users?page=${request.page}&pageSize=${request.pageSize}&orderBy=
      ${request.orderBy}&orderDirection=${request.orderDirection}${queryString}`);


    //return this.http.get<any>(this.url + 'users');
  }

  internalUsers(request: SearchRequest) {

    if (request.filterStrings.join() == '') {
      return this.http.get<SearchResult>(`${this.url}users/staff?page=${request.page}&pageSize=${request.pageSize}&orderBy=
        ${request.orderBy}&orderDirection=${request.orderDirection}`);
    }

    var queryString = '';

    for (var i = 0; i < request.filterStrings.length; i++) {
      if (request.filterStrings[i] !== '' && request.filterStrings[i] !== null) {
        queryString += `&filterColumns=${request.filterColumns[i]}&filterStrings=${request.filterStrings[i]}`
      }
    }

    return this.http.get<SearchResult>(`${this.url}users/staff?page=${request.page}&pageSize=${request.pageSize}&orderBy=
      ${request.orderBy}&orderDirection=${request.orderDirection}${queryString}`);

  }

  externalUsers(request: SearchRequest) {

    if (request.filterStrings.join() == '') {
      return this.http.get<SearchResult>(`${this.url}users/clients?page=${request.page}&pageSize=${request.pageSize}&orderBy=
        ${request.orderBy}&orderDirection=${request.orderDirection}`);
    }

    var queryString = '';

    for (var i = 0; i < request.filterStrings.length; i++) {
      if (request.filterStrings[i] !== '' && request.filterStrings[i] !== null) {
        queryString += `&filterColumns=${request.filterColumns[i]}&filterStrings=${request.filterStrings[i]}`
      }
    }

    return this.http.get<SearchResult>(`${this.url}users/clients?page=${request.page}&pageSize=${request.pageSize}&orderBy=
      ${request.orderBy}&orderDirection=${request.orderDirection}${queryString}`);

  }

  private getStaffLookup() {

    this.http.get<SearchResult>(`${this.url}users/staff?page=1&pageSize=10000&orderBy=LastName&orderDirection=asc`).subscribe(results => {

      let users = [];
      for (const user of results.items) {
        users.push({ name: user.firstName + ' ' + user.lastName, id: user.id })
      }

      this.staffLookupSubject.next(users);
    });
  }

  userDetails(id) {
    return this.http.get<any>(this.url + 'users/' + id);
  }

  addUser(userDetails: any) {
    return this.http.put<any>(this.url + 'users', userDetails, httpOptions);
  }

  updateUser(id, userDetails: any) {
    return this.http.post<any>(this.url + 'users/' + id, userDetails, httpOptions);
  }

  companyGroups() {
    return this.http.get<any>(this.url + 'companygroups');
  }

  roles() {
    return this.http.get<any>(this.url + 'auth/roles');
  }

  internalRoles() {
    return this.http.get<any>(this.url + 'auth/internalroles');
  }

  externalRoles() {
    return this.http.get<any>(this.url + 'auth/externalroles');
  }

  teamLeaders() {
    return this.http.get<any>(`${this.url}users/teamleaders`);
  }

  paralegals() {
    return this.http.get<any>(`${this.url}users/paralegals`);
  }

  admins() {
    return this.http.get<any>(`${this.url}users/admins`);
  }

  adminsAndParalegals() {
    return this.http.get<any>(`${this.url}users/adminsandparalegals`);
  }

  addCompanyGroup(companyGroupDetails: any) {
    return this.http.put<any>(this.url + 'companygroups', companyGroupDetails, httpOptions);
  }

  updateCompanyGroup(companyGroupDetails: any) {
    return this.http.post<any>(this.url + 'companygroups', companyGroupDetails, httpOptions);
  }

  companyGroupsDetails(id) {
    return this.http.get<any>(this.url + 'companygroups/' + id);
  }

  companyGroupUsers(id) {
    return this.http.get<any>(this.url + 'users/companygroup/' + id);
  }

  companyGroupUsersForUser() {
    return this.http.get<any>(this.url + 'users/companygroupusers');
  }

  companyGroupFAsForUser() {
    return this.http.get<any>(this.url + 'users/companygroupfas');
  }

}
