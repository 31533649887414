/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./pagenotfound.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../header/header.component.ngfactory";
import * as i3 from "../header/header.component";
import * as i4 from "../side-menu/side-menu-event.service";
import * as i5 from "../auth/auth.service";
import * as i6 from "@angular/flex-layout/core";
import * as i7 from "./pagenotfound.component";
var styles_PagenotfoundComponent = [i0.styles];
var RenderType_PagenotfoundComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PagenotfoundComponent, data: {} });
export { RenderType_PagenotfoundComponent as RenderType_PagenotfoundComponent };
export function View_PagenotfoundComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(1, 245760, null, 0, i3.HeaderComponent, [i4.SideMenuEventService, i5.AuthService, i6.MediaObserver], { showMenu: [0, "showMenu"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "content mat-typography bg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "content-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Page Not Found"]))], function (_ck, _v) { var currVal_0 = false; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PagenotfoundComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-pagenotfound", [], null, null, null, View_PagenotfoundComponent_0, RenderType_PagenotfoundComponent)), i1.ɵdid(1, 114688, null, 0, i7.PagenotfoundComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PagenotfoundComponentNgFactory = i1.ɵccf("app-pagenotfound", i7.PagenotfoundComponent, View_PagenotfoundComponent_Host_0, {}, {}, []);
export { PagenotfoundComponentNgFactory as PagenotfoundComponentNgFactory };
