import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-accessdenied',
  templateUrl: './accessdenied.component.html',
  styleUrls: ['./accessdenied.component.scss']
})
export class AccessdeniedComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
