import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable()
export class LookupsService {

  private url = environment.apiUrl;

  constructor(private http: HttpClient, public router: Router) {


  }

  getCaseTypes() {
    return this.http.get<any>(this.url + "casetypes");
  }

  getAllowedCaseTypesForCase(id: number) {
    return this.http.get<any>(this.url + "casetypes/" + id);
  }

  getTitles() {
    return this.http.get<any>(this.url + "titles");
  }

  getMartialStatuses() {
    return this.http.get<any>(this.url + "maritalstatuses");
  }

  getCancellationReasons() {
    return this.http.get<any>(this.url + "cancellationreasons");
  }

  getArchiveReasons() {
    return this.http.get<any>(this.url + "archivereasons");
  }

  getCaseTiers() {
    return this.http.get<any>(this.url + "casetiers");
  }

  getFileTypes() {
    return this.http.get<any>(this.url + "filetypes");
  }

  getLenders() {
    return this.http.get<any>(this.url + "lenders");
  }

  getLender(id: number) {
    return this.http.get<any>(this.url + "lenders/" + id);
  }

  getExternalConsultants() {
    return this.http.get<any>(this.url + "externalconsultants/activeconsultants");
  }

  getExternalConsultant(id: number) {
    return this.http.get<any>(this.url + "externalconsultants/" + id);
  }

  getExternalConsultantsForCase(caseId: number) {
    return this.http.get<any>(this.url + `externalconsultants/activeconsultantsforcase/${caseId}`);
  }

  getTravellingConsultantsForCase(caseId: number) {
    return this.http.get<any>(this.url + `externalconsultants/TravellingConsultantsForCase/${caseId}`);
  }

  getLendersSolicitors() {
    return this.http.get<any>(this.url + "lenderssolicitors");
  }

  getLendersSolicitor(id: number) {
    return this.http.get<any>(this.url + "lenderssolicitors/" + id);
  }

  getLendersSolicitorEmails() {
    return this.http.get<any>(this.url + "lenderssolicitorsemails/");
  }
}
