import { Component, OnInit, Input } from '@angular/core';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { SideMenuEventService } from '../side-menu/side-menu-event.service';
import { AuthService } from '../auth/auth.service';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public loggedIn = null;
  isSmall = false;
  menuSubscription: Subscription;
  authSubscription: Subscription;
  mediaSubscription: Subscription;
  isProd = environment.production;
  isStaging = environment.isStaging;

  constructor(private sideMenuService: SideMenuEventService, public authService: AuthService, mediaObserver: MediaObserver) {


    mediaObserver.media$.subscribe((change: MediaChange) => {
      this.isSmall = change.mqAlias == 'sm' || change.mqAlias == 'xs';
    });

    this.sideMenuService.currentStatus.subscribe(result => {
    });

    this.authService.isLogin.subscribe(x => {
      this.loggedIn = x;
    });
  }

  faBars = faBars;
  @Input() showMenu = true;

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.menuSubscription) {
      this.menuSubscription.unsubscribe();
    }

    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }

    if (this.mediaSubscription) {
      this.mediaSubscription.unsubscribe();
    }
  }


  menuClick() {
    this.sideMenuService.toggleSide();
  }
}
