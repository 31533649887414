<app-header [showMenu]="false"></app-header>

<div class="content mat-typography bg" ngClass.xs="size-xs">
    <div class="content-container">

      <app-card-basic cardTitle="Login">
        <div card-body>
            <div>
                <mat-form-field class="full-width">
                    <input matInput [(ngModel)]="emailAddress" placeholder="Email Address" autocomplete="username email">
                </mat-form-field>
                <br />
                <mat-form-field class="full-width">
                    <input matInput [(ngModel)]="password" placeholder="Password" (keyup.enter)="login()" type="password" autocomplete="password">
                </mat-form-field>

                <a [routerLink]="'/forgotpassword'" id="forgotpasswordlink">Forgot Password</a>
                <br />
                

                <div>
                    {{authService.errorMessage}}
                </div>

                <div>
                    <button *ngIf="!loggingIn" mat-raised-button style="float: right;" (click)="login()" color="primary">Login</button>

                    <button *ngIf="loggingIn" mat-icon-button disabled color="primary" style="float: right;">
                        <mat-icon>
                            <mat-spinner color="primary" diameter="20">
                            </mat-spinner>
                        </mat-icon>
                    </button>
                </div>


            </div>
        </div>
      </app-card-basic>

  </div>

</div>
