<app-header [showMenu]="false"></app-header>

<div class="content mat-typography bg">
    <div class="content-container">

      <app-card-basic cardTitle="Reset Password">
        <div card-body>
            <div>
                <mat-form-field class="full-width">
                    <input matInput [(ngModel)]="emailAddress" placeholder="Email Address" type="text">
                </mat-form-field>
                <br />
                <mat-form-field class="full-width">
                    <input matInput [(ngModel)]="newpassword" placeholder="New Password" type="password">
                </mat-form-field>
                <br />
                <mat-form-field class="full-width">
                    <input matInput [(ngModel)]="retypePassword" placeholder="Retype Password" type="password">
                </mat-form-field>
                <br />
                <mat-form-field class="full-width invisible">
                    <input matInput [(ngModel)]="passwordResetToken" placeholder="Reset Code" type="text">
                </mat-form-field>
                <button *ngIf="!creatingPassword" mat-raised-button style="float: right;" (click)="createNewPassword()" color="primary">Update Password</button>

                <button *ngIf="creatingPassword" mat-icon-button disabled color="primary" style="float: right;">
                    <mat-icon>
                        <mat-spinner color="primary" diameter="20">
                        </mat-spinner>
                    </mat-icon>
                </button>

            </div>
        </div>
      </app-card-basic>

  </div>

</div>
