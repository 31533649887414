import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
var SearchService = /** @class */ (function () {
    //public caseAllocated = new EventEmitter();
    //public caseAllocatedObservable = this.caseAllocated.asObservable();
    function SearchService(http) {
        this.http = http;
        this.casesUrl = environment.apiUrl + 'cases/';
        this.usersUrl = environment.apiUrl + 'users/';
        this.companyGroupsUrl = environment.apiUrl + 'companygroups/';
        this.externalConsultantsUrl = environment.apiUrl + 'externalconsultants/';
    }
    SearchService.prototype.searchCases = function (request) {
        return this.http.post(this.casesUrl + 'search', { searchTerm: request }, httpOptions);
    };
    SearchService.prototype.searchByCaseStatusType = function (caseStatusType, request) {
        return this.http.post(this.casesUrl + 'SearchByCaseStatusType/' + caseStatusType, { searchTerm: request }, httpOptions);
    };
    SearchService.prototype.searchInternalUsers = function (request) {
        return this.http.post(this.usersUrl + 'searchinternal', { searchTerm: request }, httpOptions);
    };
    SearchService.prototype.searchExternalUsers = function (request) {
        return this.http.post(this.usersUrl + 'searchexternal', { searchTerm: request }, httpOptions);
    };
    SearchService.prototype.searchCompanyGroups = function (request) {
        return this.http.post(this.companyGroupsUrl + 'search', { searchTerm: request }, httpOptions);
    };
    SearchService.prototype.searchConsultants = function (request) {
        return this.http.post(this.externalConsultantsUrl + 'search', { searchTerm: request }, httpOptions);
    };
    SearchService.ngInjectableDef = i0.defineInjectable({ factory: function SearchService_Factory() { return new SearchService(i0.inject(i1.HttpClient)); }, token: SearchService, providedIn: "root" });
    return SearchService;
}());
export { SearchService };
