<h1 mat-dialog-title>File Uploader</h1>
<div mat-dialog-content class="mat-typography">


  <div class="content-container">
    <mat-form-field class="full-width">
      <mat-select placeholder="Select Type" [(ngModel)]="fileTypeId" (selectionChange)="typeChanged()">
        <mat-option *ngFor="let filetype of fileTypes" [value]="filetype.id" [value]="0">{{filetype.fileTypeName}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="full-width" *ngIf="fileTypeId == 12">
      <input matInput [(ngModel)]="description" placeholder="Description" type="text">
    </mat-form-field>

    <button mat-mini-fab (click)="fileInput.click()" color="primary" [disabled]="!fileTypeId">
      <fa-icon [icon]="faPaperclip"></fa-icon>
    </button>
    <br/>
    <input #fileInput type="file" (change)="uploadFiles( fileInput.files ) ; fileInput.value = null;" [multiple]="false" class="" style="display:none" />
    <br/>

    <span class="errorMessage" *ngIf="hasError">{{errorMessage}}</span>
  </div>

</div>
