import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

//Material
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './account/login/login.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule,  MatProgressSpinnerModule, MatSnackBarModule,  MatIconModule } from '@angular/material';
import { ResetpasswordComponent } from './account/resetpassword/resetpassword.component';
import { ForgotpasswordComponent } from './account/forgotpassword/forgotpassword.component';
import { SharedModule } from './shared/shared.module';
import { MatSidenavModule } from '@angular/material/sidenav';
import { SideMenuEventService } from './shared/side-menu/side-menu-event.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RequestInterceptor } from './shared/auth/request.interceptor';
import { AuthService } from './shared/auth/auth.service';
import { ExpiredComponent } from './account/expired/expired.component';
import { AccessdeniedComponent } from './shared/accessdenied/accessdenied.component';
import { PagenotfoundComponent } from './shared/pagenotfound/pagenotfound.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ResetpasswordComponent,
    ForgotpasswordComponent,
    ExpiredComponent,
    AccessdeniedComponent,
    PagenotfoundComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    SharedModule.forRoot(),
    MatSidenavModule,
    HttpClientModule,
    MatIconModule,
    FlexLayoutModule
  ],
  providers: [
    SideMenuEventService,
    //AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true
  },
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
