import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import { LookupsService } from '../../portal/services/lookups.service';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { faPaperclip } from '@fortawesome/pro-light-svg-icons';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

export interface DialogData {
  fileName: '';
}

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})

export class FileUploaderComponent {

  private url = environment.apiUrl + 'files/';

  public faPaperclip = faPaperclip;
  public fileTypeId;
  public description = "";

  private caseId: number;

  public hasError: boolean = false;
  public errorMessage: string;

  fileTypes: any[];

  fileDetails = this.fb.group({
    fileType: ['', Validators.required],
    filePath: ['', Validators.required],
    individualStatusId: new FormControl({ value: null, disabled: true })
  }, { });


  constructor(public dialogRef: MatDialogRef<FileUploaderComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData,
  private lookupsService: LookupsService, private fb: FormBuilder, private httpClient: HttpClient) {

    this.caseId = this.data['caseId'];
    this.lookupsService.getFileTypes().subscribe(result => {
      this.fileTypes = result;
    });

  }

  public uploadFiles(files: File[]) {

    // Should only be allowing upload of a single file
    var file = files[0];

    // error handling done in back end
    this.uploadFile(file).subscribe((result) => {

      this.dialogRef.close(true)
    }, error => {

      this.hasError = true;

      if(error.status == 500)
        this.errorMessage = 'Internal Server Error';
      else
        this.errorMessage = error.error;
    });

  }


  public uploadFile(file: File) {

    let formData = new FormData();
    formData.append('file', file);
    formData.append('fileTypeId', this.fileTypeId)
    formData.append('caseId', this.caseId.toString())
    formData.append('description', this.description)

    return this.httpClient.post(this.url, formData);
  }

  typeChanged(){
    this.description = "";
  }
}
