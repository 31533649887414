import * as tslib_1 from "tslib";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, NavigationStart } from '@angular/router';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, throwError, interval, Subscription } from 'rxjs';
import { map, catchError, filter, tap } from 'rxjs/operators';
import * as JWT from 'jwt-decode';
import { MatDialog } from '@angular/material';
var httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
//Totally Stolen from FNOL
var AuthService = /** @class */ (function () {
    function AuthService(http, router, matDialog) {
        var _this = this;
        this.http = http;
        this.router = router;
        this.loggingIn = false;
        this.url = environment.apiUrl + 'auth/';
        this.refreshInProgress = false;
        this.isLoginSubject = new BehaviorSubject(null);
        this.isLogin = this.isLoginSubject.asObservable();
        this.isLoggedIn = null;
        this.getData();
        this.isAuthenticated().then(function (result) {
            if (result) {
                _this.startRefresh();
            }
        });
        /*if (!this.timer) {
          this.timer = setTimeout(() => this.refreshAccessToken2().subscribe(), 1000);
        }*/
        //this.scheduleTokenRefresh();
        router.events.pipe(filter(function (event) { return event instanceof NavigationStart; }), tap(function () { return matDialog.closeAll(); })).subscribe();
    }
    AuthService.prototype.isAuthenticated = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.accessToken) {
                            this.getData();
                        }
                        if (!this.accessToken || !this.refreshToken === null || !this.refreshTokenExpiry) {
                            return [2 /*return*/, false];
                        }
                        if (!(this.isLoggedIn == null || !this.isLoggedIn)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.refreshAccessToken2()];
                    case 1:
                        _a.sent();
                        this.isLoggedIn = this.accessToken && Date.now() <= this.refreshTokenExpiry;
                        this.isLoginSubject.next(this.isLoggedIn);
                        _a.label = 2;
                    case 2: return [2 /*return*/, this.isLoggedIn];
                }
            });
        });
    };
    AuthService.prototype.login2 = function (userDetails) {
        var _this = this;
        this.errorMessage = '';
        this.loggingIn = true;
        return this.http.post(this.url + 'login', userDetails, httpOptions)
            .pipe(catchError(function (error) {
            _this.loggingIn = false;
            console.log(error);
            _this.errorMessage = "Login Failed";
            if (error.status == 999)
                _this.errorMessage = error.error;
            else {
                if (error.error !== null && error.error.message !== undefined) {
                    _this.errorMessage = error.error.message;
                }
            }
            _this.isLoginSubject.next(false);
            return throwError(error, null);
        }), map(function (response) {
            _this.setData(response, false);
            _this.startRefresh();
            _this.router.navigateByUrl('/');
            _this.loggingIn = false;
        }));
    };
    AuthService.prototype.logout = function () {
        this.clearData();
        this.router.navigateByUrl("/login");
    };
    AuthService.prototype.getToken = function () {
        return this.accessToken;
    };
    AuthService.prototype.setData = function (authResult, refresh) {
        if (!authResult && refresh)
            return;
        this.accessToken = authResult.token;
        this.refreshToken = authResult.refreshToken;
        this.firstName = authResult.firstName;
        this.lastName = authResult.lastName;
        this.role = authResult.role;
        this.isTeamLeader = authResult.isTeamLeader;
        this.userCanAllocateCases = authResult.canAllocateCases;
        this.userCanReallocateCases = authResult.canReallocateCases;
        this.userCanSeeAdminActions = authResult.canSeeAdminActions;
        this.refreshTokenExpiry = new Date(authResult.expires).getTime();
        localStorage.setItem('access_token', JSON.stringify(this.accessToken));
        localStorage.setItem('refresh_token', JSON.stringify(this.refreshToken));
        localStorage.setItem('refresh_token_expiry', JSON.stringify(this.refreshTokenExpiry));
        if (!refresh) {
            localStorage.setItem('first_name', JSON.stringify(this.firstName));
            localStorage.setItem('last_name', JSON.stringify(this.lastName));
            localStorage.setItem('role', JSON.stringify(this.role));
            localStorage.setItem('is_team_leader', JSON.stringify(this.isTeamLeader));
            localStorage.setItem('override_can_allocate_cases', JSON.stringify(this.userCanAllocateCases));
            localStorage.setItem('override_can_reallocate_cases', JSON.stringify(this.userCanReallocateCases));
            localStorage.setItem('override_can_see_admin_actions', JSON.stringify(this.userCanSeeAdminActions));
        }
        this.isLoginSubject.next(true);
    };
    AuthService.prototype.getData = function () {
        this.accessToken = JSON.parse(localStorage.getItem('access_token'));
        this.refreshToken = JSON.parse(localStorage.getItem('refresh_token'));
        //console.log('Getting refresh token from storage: ', this.refreshToken)
        this.refreshTokenExpiry = JSON.parse(localStorage.getItem('refresh_token_expiry'));
        this.userID = JSON.parse(localStorage.getItem('user_id'));
    };
    AuthService.prototype.clearData = function () {
        localStorage.setItem('access_token', null);
        localStorage.setItem('refresh_token', null);
        localStorage.setItem('refresh_token_expiry', null);
        localStorage.setItem('user_id', null);
        localStorage.setItem('first_name', null);
        localStorage.setItem('last_name', null);
        //localStorage.setItem('role', null);
        this.accessToken = null;
        this.refreshToken = null;
        this.refreshTokenExpiry = null;
        this.isLoginSubject.next(false);
    };
    /*public unscheduleTokenRefresh() {
      if (!this.refreshSubscription) return;
      this.refreshSubscription.unsubscribe();
    }*/
    AuthService.prototype.refreshAccessToken2 = function () {
        var _this = this;
        this.getData();
        var refreshTokenData = {
            "Token": this.accessToken,
            "RefreshToken": this.refreshToken
        };
        //console.log('Refreshing with token(2): ', this.refreshToken)
        return this.http.post(this.url + 'refresh', refreshTokenData, httpOptions).pipe(map(function (response) {
            _this.setData(response, true);
            return response;
        }));
    };
    AuthService.prototype.startRefresh = function () {
        var _this = this;
        this.refreshSubscription = interval(300000)
            .subscribe(function () {
            if (!_this.refreshInProgress) {
                _this.refreshInProgress = true;
                _this.refreshAccessToken2().subscribe(function () {
                    _this.refreshInProgress = false;
                }, function (error) {
                    _this.refreshInProgress = false;
                    _this.stopRefresh();
                    _this.logout();
                });
            }
        });
    };
    AuthService.prototype.stopRefresh = function () {
        this.refreshSubscription.unsubscribe();
    };
    /*refresh(): Promise<any> {
  
  
  
      this.getData();
  
      var refreshTokenData = {
        "Token": this.accessToken,
        "RefreshToken": this.refreshToken
      };
  
      return this.http.post<any>(this.url + 'refresh', refreshTokenData, httpOptions).pipe(
        map(response => {
          this.setData(response, true);
          return response;
        })
      ).toPromise();
  
    }*/
    AuthService.prototype.getName = function () {
        this.firstName = localStorage.hasOwnProperty('first_name') ? JSON.parse(localStorage.getItem('first_name')) : '';
        this.lastName = localStorage.hasOwnProperty('last_name') ? JSON.parse(localStorage.getItem('last_name')) : '';
        if (this.firstName != '') {
            if (this.lastName != '') {
                return this.firstName + " " + this.lastName;
            }
            return this.firstName;
        }
        return '';
    };
    AuthService.prototype.getRole = function () {
        this.role = JSON.parse(localStorage.getItem('role'));
        return this.role;
    };
    AuthService.prototype.getIsTeamLeader = function () {
        this.isTeamLeader = JSON.parse(localStorage.getItem('is_team_leader'));
        return this.isTeamLeader;
    };
    AuthService.prototype.getUserCanAllocateCases = function () {
        this.userCanAllocateCases = JSON.parse(localStorage.getItem('override_can_allocate_cases'));
        return this.userCanAllocateCases;
    };
    AuthService.prototype.getUserCanReallocateCases = function () {
        this.userCanReallocateCases = JSON.parse(localStorage.getItem('override_can_reallocate_cases'));
        return this.userCanReallocateCases;
    };
    AuthService.prototype.getUserCanSeeAdminActions = function () {
        this.userCanSeeAdminActions = JSON.parse(localStorage.getItem('override_can_see_admin_actions'));
        return this.userCanSeeAdminActions;
    };
    AuthService.prototype.hasRole = function (name) {
        if (!this.accessToken)
            return false;
        var token = JWT(this.accessToken);
        if (token.hasOwnProperty(name)) {
            return token[name] == '1' ? true : false;
        }
        return false;
    };
    AuthService.prototype.hasAnyRoleId = function (ids) {
        this.role = JSON.parse(localStorage.getItem('role'));
        for (var _i = 0, ids_1 = ids; _i < ids_1.length; _i++) {
            var id = ids_1[_i];
            if (this.role == id)
                return true;
        }
        return false;
    };
    AuthService.prototype.hasRoleId = function (id) {
        this.role = JSON.parse(localStorage.getItem('role'));
        return (this.role == id);
    };
    AuthService.prototype.isFA = function () {
        this.role = JSON.parse(localStorage.getItem('role'));
        return (this.role == 1);
    };
    AuthService.prototype.isFAManager = function () {
        this.role = JSON.parse(localStorage.getItem('role'));
        return (this.role == 2);
    };
    AuthService.prototype.isFAAdmin = function () {
        this.role = JSON.parse(localStorage.getItem('role'));
        return (this.role == 3);
    };
    AuthService.prototype.isPortalAdmin = function () {
        this.role = JSON.parse(localStorage.getItem('role'));
        return (this.role == 4);
    };
    AuthService.prototype.isPortalAdminTL = function () {
        this.role = JSON.parse(localStorage.getItem('role'));
        return (this.role == 4 && this.getIsTeamLeader());
    };
    AuthService.prototype.isPortalParaLegal = function () {
        this.role = JSON.parse(localStorage.getItem('role'));
        return (this.role == 5);
    };
    AuthService.prototype.isPortalParaLegalTL = function () {
        this.role = JSON.parse(localStorage.getItem('role'));
        return (this.role == 5 && this.getIsTeamLeader());
    };
    AuthService.prototype.isPortalManager = function () {
        this.role = JSON.parse(localStorage.getItem('role'));
        return (this.role == 6);
    };
    AuthService.prototype.isALStaff = function () {
        this.role = JSON.parse(localStorage.getItem('role'));
        return (this.role == 4 || this.role == 5 || this.role == 6);
    };
    AuthService.prototype.currentUserID = function () {
        if (!this.accessToken)
            return -1;
        var token = JWT(this.accessToken);
        if (token.hasOwnProperty('unique_name')) {
            return token['unique_name'];
        }
        return -1;
    };
    AuthService.prototype.canAddNewCases = function () {
        return this.isFA() || this.isFAAdmin() || this.isFAManager() || this.isPortalAdmin() || this.isPortalAdminTL();
    };
    AuthService.prototype.canSeeAdminActionList = function () {
        return this.isPortalManager() || this.isPortalAdmin() || this.isPortalAdminTL() || this.overrideCanSeeAdminActionList();
    };
    AuthService.prototype.canSeeParalegalActionList = function () {
        return this.isPortalManager() || this.isPortalParaLegal() || this.isPortalParaLegalTL();
    };
    AuthService.prototype.overrideCanSeeAdminActionList = function () {
        return this.isPortalParaLegalTL() && this.getUserCanSeeAdminActions();
    };
    AuthService.prototype.canSeeInstructedCases = function () {
        return true; // All users
    };
    AuthService.prototype.canSeeUnallocatedCases = function () {
        return this.isPortalAdminTL() || this.isPortalManager() || (this.isPortalParaLegalTL() && this.getUserCanAllocateCases());
    };
    AuthService.prototype.canAllocateCases = function () {
        return this.isPortalAdminTL() || this.isPortalManager() || (this.isPortalParaLegalTL() && this.getUserCanAllocateCases());
    };
    AuthService.prototype.canReallocateCases = function () {
        return this.isPortalAdminTL() || this.isPortalManager() || (this.isPortalParaLegalTL() && this.getUserCanReallocateCases());
    };
    AuthService.prototype.canReallocateTasks = function () {
        return this.isPortalAdminTL() || this.isPortalManager() || (this.isPortalParaLegalTL() && this.getUserCanReallocateCases());
    };
    AuthService.prototype.canSeeCancelledCases = function () {
        //return this.isPortalAdmin();
        return true;
    };
    AuthService.prototype.canSeeCompletedCases = function () {
        //return this.isPortalAdmin() || this.isPortalManager();
        return true;
    };
    AuthService.prototype.canSeeUninstructedCases = function () {
        // Only should be seen by those that can create cases or have the ability to archive them
        return !this.isPortalParaLegal() && !this.isPortalParaLegalTL();
    };
    AuthService.prototype.canAdminUsersAndGroups = function () {
        return this.isPortalAdmin() || this.isPortalAdminTL() || this.isPortalManager();
    };
    AuthService.prototype.canAdminInternalUsers = function () {
        return this.isPortalManager() || this.isPortalAdminTL();
    };
    AuthService.prototype.canAdminConsultants = function () {
        return this.isPortalAdminTL() || this.isPortalManager();
    };
    AuthService.prototype.canDeleteFiles = function () {
        return this.isPortalAdminTL() || this.isPortalParaLegalTL() || this.isPortalManager();
    };
    return AuthService;
}());
export { AuthService };
