<app-header [showMenu]="false"></app-header>

<div class="content mat-typography bg">
    <div class="content-container">

      <h2>Page Not Found</h2>

  </div>

</div>
