<div  ngClass.xs="size-xs" class="container mat-typography">
    <div class="main">
        <div class="menu">
                <button mat-button (click)="menuClick()" *ngIf="showMenu">
                    <fa-icon [icon]="faBars" size="lg"></fa-icon>
                </button>
        </div>
        <div [ngClass]="{'logo': isProd, 'logo-training': !isProd && !isStaging, 'logo-staging': !isProd && isStaging}"routerLink="/"></div>
        <div class="spacer"></div>
        <div *ngIf="!isSmall && loggedIn" class="search"><app-search-bar searchType="Cases"></app-search-bar></div>
        <div class="spacer"></div>
        <div class="username" *ngIf="loggedIn">{{authService.getName()}}</div>
    </div>
    <div class="foot bg-accent">

    </div>

</div>
