import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  templateUrl: './expired.component.html',
  styleUrls: ['./expired.component.scss']
})
export class ExpiredComponent implements OnInit {

  emailAddress: string;
  password: string;

  loggingIn: boolean = false;

  constructor() { }

  ngOnInit() {
  }


}
